import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router-dom for routing
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

function PaymentFailure() {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <ErrorOutlineOutlinedIcon style={{ fontSize: 100, color: "#EF4444" }} />
      <h2 className="text-3xl font-bold mt-6">Payment Failed!</h2>
      <p className="text-gray-600 mt-2">
        Oops! Something went wrong with your payment.
      </p>
      <Link
        to="/"
        className="mt-4 bg-[#FF3131] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Back to Home
      </Link>
    </div>
  );
}

export default PaymentFailure;
