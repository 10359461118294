import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { useLocation, useNavigate } from "react-router-dom";
import { UpdateReferenceObject } from "../apis/referneceObjects/updateReferenceObject";
import { AuthContext } from "../context/AuthContext";
import BasicDatePicker from "../utils/MUIDatePIcker";
import { AddReferenceObject } from "../apis/referneceObjects/addReferenceObject";

function UpdateReferenceForm() {
  const { t } = useTranslation();
  const [price, setPrice] = useState();
  const [propertyType, setPropertyType] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [address, setAddress] = useState("");

  const [squareMeter, setSquareMeter] = useState("");
  const [plotSize, setPlotSize] = useState("");
  const [year, setYear] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const updateReference = location?.state;
  const [strengthsList, setStrengthsList] = useState([
    {
      strengthName: "",
      strengthAmount: 0,

      strengthData: "",
      strengthPercent: 0,
    },
  ]);
  const { isLoggedIn, logOut } = useContext(AuthContext);

  const auth = isLoggedIn();

  const [weaknessesList, setWeaknessesList] = useState([
    {
      weaknessName: "",
      weaknessAmount: 0,
      weaknessData: "",
      weaknessPercent: 0,
    },
  ]);

  const [date, setDate] = useState("");

  useEffect(() => {
    console.log(location.state);
    if (updateReference?.status === "Update") {
      const updatedStrengthsArray = updateReference.data.Strengths.map(
        ({ id, createdAt, updatedAt, ...rest }) => ({
          strengthId: id, // renaming 'id' to 'weaknessId'
          ...rest, // spread the remaining properties from the original object
        })
      );
      const updatedWeaknessesArray = updateReference.data.Weaknesses.map(
        ({ id, createdAt, updatedAt, ...rest }) => ({
          weaknessId: id, // renaming 'id' to 'weaknessId'
          ...rest, // spread the remaining properties from the original object
        })
      );

      setStrengthsList(updatedStrengthsArray);

      // Now set the updated array in your state
      setWeaknessesList(updatedWeaknessesArray);

      setWeaknessesList(updatedWeaknessesArray);
      setPrice(updateReference.data.sellingPrice);
      setPropertyType(updateReference.data.propertyType);
      setAddress(updateReference.data.address);
      setSquareMeter(updateReference.data.livingArea);
      setPlotSize(updateReference.data.plotSize);
      setNeighborhood(updateReference.data.enableHood);
      setYear(updateReference.data.constructionYear);
      setZipCode(updateReference.data.zipCode);
      setHouseNumber(updateReference.data.houseNo);
      setCity(updateReference.data.city);
      console.log(updateReference.data.sellingDate);
      setDate(updateReference.data.sellingDate);
    }
  }, []);

  const handleInputChange = (index, field, value) => {
    const updatedStrengthsList = [...strengthsList];
    if (field === "strengthAmount") {
      // Parse value to an integer if the field is "strengthAmount"
      value = parseInt(value, 10) || 0; // Ensure a valid integer, default to 0 if parsing fails
      if (value <= 0) {
        value = 0; // Ensure the amount is greater than 0
      }
    }
    if (field === "strengthPercent") {
      // Parse value to a float if the field is "strengthPercent"
      value = parseFloat(value, 10) || 0; // Ensure a valid float, default to 0 if parsing fails
      if (value < 0) {
        value = 0; // Ensure the percentage is not negative
      } else if (value > 100) {
        value = 100; // Ensure the percentage does not exceed 100
      }
    }
    updatedStrengthsList[index][field] = value;
    setStrengthsList(updatedStrengthsList);
  };
  const handleInputChangeWeakness = (index, field, value) => {
    const updatedWeaknessesList = [...weaknessesList];

    if (field === "weaknessAmount") {
      // Parse value to an integer if the field is "strengthAmount"
      value = parseInt(value, 10) || 0; // Ensure a valid integer, default to 0 if parsing fails
      if (value <= 0) {
        value = 0; // Ensure the amount is greater than 0
      }
    }
    if (field === "weaknessPercent") {
      // Parse value to a float if the field is "strengthPercent"
      value = parseFloat(value, 10) || 0; // Ensure a valid float, default to 0 if parsing fails
      if (value < 0) {
        value = 0; // Ensure the percentage is not negative
      } else if (value > 100) {
        value = 100; // Ensure the percentage does not exceed 100
      }
    }

    updatedWeaknessesList[index][field] = value;

    setWeaknessesList(updatedWeaknessesList);
  };

  const handleAddStrength = () => {
    setStrengthsList([
      ...strengthsList,
      {
        strengthName: "",
        strengthAmount: 0,
        strengthData: "",
        strengthPercent: 0,
      },
    ]);
  };
  const handleAddWeakness = () => {
    setWeaknessesList([
      ...weaknessesList,
      {
        weaknessName: "",
        weaknessAmount: 0,
        weaknessData: "",
        weaknessPercent: 0,
      },
    ]);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = [];

    // Validate year
    const currentYear = new Date().getFullYear();
    if (!year || !/^\d{4}$/.test(year) || year < 1900 || year > 2099) {
      errors.push(t("errors.yearError"));
    }

    // Validate other fields
    if (!price) {
      errors.push(t("errors.priceRequired"));
    } else if (price < 0) {
      errors.push(t("errors.priceNegative"));
    }

    if (!propertyType) errors.push(t("errors.propertyTypeRequired"));
    if (!date) errors.push(t("errors.dateRequired"));
    if (!address) errors.push(t("errors.addressRequired"));

    if (!squareMeter) {
      errors.push(t("errors.squareMeterRequired"));
    } else if (squareMeter < 0) {
      errors.push(t("errors.squareMeterNegative"));
    }

    if (!plotSize) {
      errors.push(t("errors.plotSizeRequired"));
    } else if (plotSize < 0) {
      errors.push(t("errors.plotSizeNegative"));
    }
    if (neighborhood === "") {
      errors.push(t("errors.neighborhoodRequired"));
    }

    // if (!zipCode) {
    //   errors.push(t("errors.zipCodeRequired"));
    // } else if (!/^\d{5}$/.test(zipCode)) {
    //   errors.push(t("errors.zipCodeInvalid"));
    // }
    if (!zipCode) {
      errors.push(t("errors.zipCodeRequired"));
    } else if (!/^[a-zA-Z0-9]+$/.test(zipCode)) {
      errors.push(t("errors.zipCodeInvalid"));
    }

    if (!houseNumber) errors.push(t("errors.houseNumberRequired"));
    if (!city) errors.push(t("errors.cityRequired"));

    // Check for weaknessesList validity
    const hasEmptyFields = weaknessesList.some(
      (weakness) =>
        !weakness.weaknessName ||
        weakness.weaknessAmount === 0 ||
        !weakness.weaknessData ||
        weakness.weaknessPercent === 0
    );

    // if (hasEmptyFields) {
    //   errors.push(t("errors.weaknessInvalid"));
    // }

    // Display errors if any and stop the process
    if (errors.length > 0) {
      errors.forEach((error) => {
        toast.error(error);
      });
      console.error(
        "Please address the following errors: " + errors.join(", ")
      );
      return;
    }

    const AddeObject = {
      valuationId: updateReference.id,

      sellingPrice: parseInt(price, 10),
      propertyType,
      sellingDate: date,
      address,
      livingArea: parseInt(squareMeter, 10),
      plotSize: parseInt(plotSize, 10),
      constructionYear: parseInt(year, 10),
      zipCode: zipCode,
      enableHood: neighborhood,
      houseNo: parseInt(houseNumber, 10),
      city,
      strengthDataa: strengthsList,
      weaknessDataa: weaknessesList,
    };

    try {
      let response;
      if (location.state?.status === "Update") {
        // Handle Update scenario
        let referenceObject = {
          valuationId: updateReference?.valuationData?.id,
          refrenceId: updateReference?.data?.id,
          sellingPrice: parseInt(price, 10),
          propertyType,
          enableHood: neighborhood,
          sellingDate: date,
          address,
          livingArea: parseInt(squareMeter, 10),
          plotSize: parseInt(plotSize, 10),
          constructionYear: parseInt(year, 10),
          zipCode: zipCode,
          houseNo: parseInt(houseNumber, 10),
          city,
          strengthDataa: strengthsList,
          weaknessDataa: weaknessesList,
        };
        response = await UpdateReferenceObject(
          referenceObject,
          auth.userData.token,
          logOut
        );
      } else {
        // Handle Add scenario
        response = await AddReferenceObject(
          AddeObject,
          auth.userData.token,
          logOut
        );
      }

      // Handle response for Update scenario
      if (location.state?.status === "Update") {
        if (response.data?.status) {
          // Success scenario
          toast.success(t("message.success"));
          navigate("/comparison", {
            state: {
              valuationId: response.data.Properites.id,
            },
          });
        } else {
          // Failure scenario
          const errorMessage = response?.data?.message || t("message.fault");
          console.log(errorMessage);
          toast.error(
            errorMessage.includes("weaknessDataa[0].weaknessName")
              ? t("message.weaknessError")
              : errorMessage.includes("strengthDataa[0].strengthName")
              ? t("message.strenghtError")
              : errorMessage
          );
        }
      } else {
        // Handle response for Add scenario
        if (response.data?.status) {
          // Success scenario
          toast.success(t("message.success"));
          navigate("/comparison", {
            state: {
              valuationId: response.data.PropertyDetails.valuationId,
            },
          });
        } else {
          // Failure scenario
          const errorMessage = response?.data?.message || t("message.fault");
          console.log(errorMessage);
          toast.error(
            errorMessage.includes("weaknessDataa[0].weaknessName")
              ? t("message.weaknessError")
              : errorMessage.includes("strengthDataa[0].strengthName")
              ? t("message.strenghtError")
              : errorMessage
          );
        }
      }
    } catch (error) {
      // Catch block for any errors
      toast.error(t("message.fault"));
    }
  };

  const handleCancel = () => {
    // Reset form fields
  };

  const roomOptions = [
    { id: "kitchen", value: t("strenghts.room.options.kitchen") },
    { id: "living_room", value: t("strenghts.room.options.livingroom") },
    { id: "bedroom", value: t("strenghts.room.options.bedroom") },
    { id: "bathroom", value: t("strenghts.room.options.bathroom") },
  ];
  const areaOptions = [
    { id: 1, value: "Terrace House" },
    { id: 2, value: "End House" },
    { id: 3, value: "Corner House" },
    { id: 4, value: "Semi Detached House" },
    { id: 5, value: "Detached House" },
  ];

  const handleDateChange = (event) => {
    setDate(event);
  };
  return (
    <div className="md:w-5/6  w-[95%] h-auto bg-white shadow-md px-8 pt-6 pb-8 mb-4 flex flex-col my-2 mt-8 border rounded-lg">
      <form onSubmit={handleSubmit} className="md:10px xl:px-16 px-0">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => console.log("draft")}
            className=" shadow-xl md:py-2.5 md:px-4 py-1 px-1.5 text-sm font-semibold rounded-sm text-white bg-[#FF3131] hover:bg-[#FF3150] focus:outline-none"
          >
            {t("button.draft")}
          </button>
        </div>

        <h1 className="text-1B1839 font-open-sans   px-3 lg:w-[60%] w-full xl:text-2xl text-x font-normal leading-normal tracking-wide mt-4">
          {t("propertySubTitle")}
        </h1>
        <div className="-mx-3  px-3 md:flex mt-2">
          <div className="md:w-1/2 px-3 ">
            <label
              className="block uppercase tracking-wide text-grey-darker text-sm semi-bold mb-2"
              htmlFor="grid-first-name"
            >
              {t("zipCode.label")}
            </label>
            <input
              className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 mb-3 outline-none "
              id="grid-first-name"
              type="text"
              placeholder={t("zipCode.placeholder")}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </div>

          <div className="md:w-1/2 px-3 ">
            <label
              className="block uppercase tracking-wide text-grey-darker text-sm semi-bold mb-2"
              htmlFor="grid-last-name4"
            >
              {t("houseNumber.label")}
            </label>
            <input
              className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 roundedfull py-2.5 px-4 rounded-2xl outline-none"
              id="grid-last-name4"
              type="number"
              placeholder={t("houseNumber.placeholder")}
              value={houseNumber}
              onChange={(e) => setHouseNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="md:w-full px-2.5 mb-6 md:mb-0 mt-2">
          <label
            className="block uppercase tracking-wide text-grey-darker text-sm semi-bold  mb-2"
            htmlFor="grid-address"
          >
            {t("address.label")}
          </label>
          <input
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-3 px-4 mb-3 outline-none "
            id="grid-address"
            type="text"
            placeholder={t("address.placeholder")}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        <div className="md:w-1/2 px-3">
          <label
            className="block uppercase tracking-wide text-grey-darker text-sm semi-bold mb-2"
            htmlFor="grid-last-name6"
          >
            {t("city.label")}
          </label>
          <input
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 outline-none"
            id="grid-last-name6"
            type="text"
            placeholder={t("city.placeholder")}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>

        <div className="lg:w-[60%] w-full px-3 mb-6 md:mb-0 mt-5">
          <label
            className="block  tracking-wide text-grey-darker xl:text-2xl text-xl   semi-bold  mb-2"
            htmlFor="grid-address"
          >
            {t("sellingPrice.label")}
          </label>
          <input
            className="appearance-none block w-full  text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-3 mb-6  mt-3 outline-none"
            id="grid-address"
            type="number"
            placeholder={t("sellingPrice.placeholder")}
            value={price}
            onChange={(e) => {
              const value = parseFloat(e.target.value); // Parse the input value to a floating-point number
              if (!isNaN(value) && value > 0) {
                // Check if the value is a valid number and greater than 0
                setPrice(value);
              }
            }}
          />
        </div>

        <div className="lg:w-[60%] w-full px-3 mb-6 md:mb-0">
          <label
            className="block  tracking-wide text-grey-darker xl:text-2xl text-xl  semi-bold  "
            htmlFor="grid-area"
          >
            {t("typeOfProperty.label")}
          </label>
          <select
            className=" border border-gray-300 text-gray-900 text-md w-full px-4 py-3 rounded-2xl outline-none mb-4  mt-3"
            id="grid-area"
            value={propertyType}
            onChange={(e) => setPropertyType(e.target.value)}
          >
            <option value="" disabled>
              {t("typeOfProperty.placeholder")}
            </option>
            {areaOptions.map((areaOption) => (
              <option key={areaOption.id} value={areaOption.value}>
                {areaOption.value}
              </option>
            ))}
          </select>
        </div>

        <div className="lg:w-[60%] w-full px-3 py-3">
          <label
            className="block  tracking-wide text-grey-darker xl:text-2xl text-xl  semi-bold     "
            htmlFor="grid-address"
          >
            {t("sellingDate.label")}
          </label>
          <div className="w-full px-3 py-3">
            {location.state?.status === "Add" ? (
              <BasicDatePicker GetDateChange={handleDateChange} />
            ) : (
              <BasicDatePicker GetDateChange={handleDateChange} date={date} />
            )}
          </div>
        </div>

        <div className="lg:w-[60%] w-full px-3 py-3">
          <label
            className="block tracking-wide text-grey-darker xl:text-2xl text-xl semi-bold"
            htmlFor="grid-square-meter"
          >
            {t("acqLivingArea.label")}
          </label>
          <input
            className="border border-gray-300 text-gray-900 text-sm w-full p-3 rounded-2xl outline-none mt-3 mb-2"
            id="grid-square-meter"
            placeholder={t("acqLivingArea.placeholder")}
            type="number"
            value={squareMeter}
            onChange={(e) => {
              if (parseInt(e.target.value) > 0) {
                setSquareMeter(parseInt(e.target.value));
              }
            }}
          />
        </div>

        <div className="lg:w-[60%] w-full px-3 md:mb-0 py-3">
          <label
            className="block tracking-wide text-grey-darker xl:text-2xl text-xl semi-bold"
            htmlFor="grid-square-meter3"
          >
            {t("plotSize.label")}
          </label>
          <input
            className="border border-gray-300 text-gray-900 text-sm w-full p-3 rounded-2xl outline-none mb-2 mt-2"
            id="grid-square-meter3"
            type="number"
            placeholder={t("plotSize.placeholder")}
            value={plotSize}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (value > 0) {
                setPlotSize(value);
              }
            }}
          />
        </div>
        <div className="lg:w-[60%] w-full  px-3 py-3">
          <label
            className="block tracking-wide text-grey-darker xl:text-2xl text-xl semi-bold mb-2 "
            htmlFor="grid-last-name5"
          >
            {t("constructionYear.label")}
          </label>
          <input
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter  md:py-3 md:px-4 py-2 px-2 rounded-2xl outline-none"
            id="grid-last-name"
            type="number"
            pattern="[0-9]{4}" // Accepts only 4-digit positive numbers
            min="1900" // Minimum value allowed
            max="2099" // Maximum value allowed
            maxLength={4} // Restricts input to 4 characters
            placeholder={t("constructionYear.placeholder")}
            value={year}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d{0,4}$/.test(value)) {
                // Allow only up to 4 digits
                setYear(parseInt(value) || ""); // Convert to integer or empty string if conversion fails
              }
            }}
          />
        </div>
        <div className="lg:w-[60%] xs:w-full xs:px-3 md:px-3 py-3 ">
          <label
            className="block tracking-wide text-grey-darker md:text-[22px] xs:text-[14px] semi-bold mb-2"
            htmlFor="grid-last-name"
          >
            {t("neighbourhood.label")}
          </label>
          <input
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter  md:py-3 md:px-4 py-2 px-2 rounded-2xl outline-none"
            id="grid-last-name"
            type="text"
            placeholder={t("neighbourhood.placeholder")}
            value={neighborhood}
            onChange={(e) => {
              const value = e.target.value;
              setNeighborhood(value);
            }}
          />
        </div>

        <div className="w-full p-8">
          {strengthsList.map((strength, index) => (
            <>
              <div key={strength.id}>
                <h1 className="text-1B1839 font-open-sans  px-3 lg:w-[60%] w-full xl:text-2xl text-x font-normal leading-normal tracking-wide mt-2 mb-2">
                  {t("strenghts.label")} {index + 1}
                </h1>
              </div>
              <div key={strength.id} className="mb-4">
                <div className="-mx-3  px-3 md:flex ">
                  <div className="md:w-1/2 px-3">
                    <select
                      id={`room-${index}`}
                      value={strength.strengthName}
                      onChange={(e) =>
                        handleInputChange(index, "strengthName", e.target.value)
                      }
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 mb-3 outline-none"
                    >
                      <option value="" disabled>
                        {t("strenghts.room.label")}
                      </option>
                      {roomOptions.map((roomOption) => (
                        <option key={roomOption.id} value={roomOption.value}>
                          {roomOption.value}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="md:w-1/2 px-3">
                    <input
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 roundedfull py-2.5 px-4 rounded-2xl outline-none"
                      id={`amount-${index}`}
                      placeholder={t("strenghts.amount")}
                      type="number"
                      value={strength.strengthAmount}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "strengthAmount",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="-mx-3  px-3 md:flex ">
                  <div className="md:w-1/2 px-3 ">
                    <label
                      className="block  tracking-wide text-grey-darker text-sm semi-bold mb-2"
                      htmlFor={`strengths-${index}`}
                    >
                      {t("strenghts.strenghts")}
                    </label>

                    <input
                      type="text"
                      id={`strengths-${index}`}
                      value={strength.strengthData}
                      onChange={(e) =>
                        handleInputChange(index, "strengthData", e.target.value)
                      }
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 mb-3 outline-none "
                    />
                  </div>

                  <div className="md:w-1/2 px-3">
                    <label
                      className="block  tracking-wide text-grey-darker text-sm semi-bold mb-2"
                      htmlFor={`strghtPercentage-${index}`}
                    >
                      {t("strenghts.strghtPercentage")}
                    </label>
                    <input
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 roundedfull py-2.5 px-4 rounded-2xl outline-none"
                      id={`strghtPercentage-${index}`}
                      value={strength.strengthPercent}
                      type="number"
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "strengthPercent",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          ))}
          <div className="flex justify-center">
            <button
              onClick={handleAddStrength}
              type="button"
              className="justify-center mx-auto shadow-xl py-2.5 px-4 text-sm font-semibold rounded-2xl text-white bg-[#FF3131] hover:bg-[#FF3150] focus:outline-none"
            >
              + {t("button.add")}
            </button>
          </div>
        </div>
        <div className="w-full p-8">
          {weaknessesList.map((weakness, index) => (
            <>
              <div key={weakness.id}>
                <h1 className="text-1B1839 font-open-sans px-3 lg:w-[60%] w-full xl:text-2xl text-x font-normal leading-normal tracking-wide mt-2 mb-2">
                  {t("weakness.label")} {index + 1}
                </h1>
              </div>
              <div key={weakness.id} className="mb-4">
                <div className="-mx-3 px-3 md:flex">
                  <div className="md:w-1/2 px-3">
                    <select
                      id={`room-${index}`}
                      value={weakness.weaknessName}
                      onChange={(e) =>
                        handleInputChangeWeakness(
                          index,
                          "weaknessName",
                          e.target.value
                        )
                      }
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 mb-3 outline-none"
                    >
                      <option value="" disabled>
                        {t("weakness.room.label")}
                      </option>
                      {roomOptions.map((roomOption) => (
                        <option key={roomOption.id} value={roomOption.value}>
                          {roomOption.value}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="md:w-1/2 px-3">
                    <input
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 roundedfull py-2.5 px-4 rounded-2xl outline-none"
                      id={`amount-${index}`}
                      placeholder={t("weakness.amount")}
                      value={weakness.weaknessAmount}
                      type="number"
                      onChange={(e) =>
                        handleInputChangeWeakness(
                          index,
                          "weaknessAmount",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="-mx-3 px-3 md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      className="block  tracking-wide text-grey-darker text-sm semi-bold mb-2"
                      htmlFor={`weaknesses-${index}`}
                    >
                      {t("weakness.weaknesses")}
                    </label>

                    <input
                      type="text"
                      id={`weaknesses-${index}`}
                      value={weakness.weaknessData}
                      onChange={(e) =>
                        handleInputChangeWeakness(
                          index,
                          "weaknessData",
                          e.target.value
                        )
                      }
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 mb-3 outline-none"
                    />
                  </div>

                  <div className="md:w-1/2 px-3">
                    <label
                      className="block  tracking-wide text-grey-darker text-sm semi-bold mb-2"
                      htmlFor={`wknPercentage-${index}`}
                    >
                      {t("weakness.wknPercentage")}
                    </label>
                    <input
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 roundedfull py-2.5 px-4 rounded-2xl outline-none"
                      id={`wknPercentage-${index}`}
                      value={weakness.weaknessPercent}
                      type="number"
                      onChange={(e) =>
                        handleInputChangeWeakness(
                          index,
                          "weaknessPercent",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          ))}
          <div className="flex justify-center">
            <button
              onClick={handleAddWeakness}
              type="button"
              className="justify-center mx-auto shadow-xl py-2.5 px-4 text-sm font-semibold rounded-2xl text-white bg-[#FF3131] hover:bg-[#FF3150] focus:outline-none"
            >
              + {t("button.add")}
            </button>
          </div>
        </div>

        <div className="mt-4 w-2/3 md:1/2 flex justify-end ml-auto">
          <button
            onClick={() => handleCancel()}
            type="button" // Add type="button" to specify it as a button
            className="w-[45%] shadow-xl py-2.5 px-4 text-sm font-semibold rounded-2xl text-black border border-[#000] focus:outline-none mr-2"
          >
            {t("button.cancel")}
          </button>
          <button
            type="submit"
            className="w-[45%] shadow-xl py-2.5 px-4 text-sm font-semibold rounded-2xl text-white bg-[#FF3131] hover:bg-[#FF3150] focus:outline-none"
          >
            {" "}
            {location?.state?.status === "Add"
              ? t("button.add")
              : t("button.update")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default UpdateReferenceForm;
