import React, { useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import "dayjs/locale/en-gb"; // Import the locale you need

export default function BasicDatePicker({ date, GetDateChange }) {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (date) {
      console.log(date);
      setSelectedDate(dayjs(date, "DD/MM/YYYY")); // Parse date string with format 'DD/MM/YYYY'
    }
  }, [date]);

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY"); // Change the format to "DD/MM/YYYY"
    GetDateChange(formattedDate);
    setSelectedDate(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              value={selectedDate ? selectedDate.format("DD/MM/YYYY") : ""}
            />
          )}
          inputFormat="DD/MM/YYYY"
          sx={{ width: "100%", borderRadius: "20px" }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
