import React from "react";
import Sidebar from "../components/SideBar";
import Header from "../components/Header";
import ValuationProperty from "../components/ValuationProperty";

const Home = () => {
  return <ValuationProperty />;
};

export default Home;
