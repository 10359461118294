import React from "react";
import { useNavigate } from "react-router-dom";
import { FaExclamationCircle } from "react-icons/fa"; // Importing the FaExclamationCircle icon from Font Awesome

const SubscriptionAlert = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen flex justify-center items-center  z-40"
      style={{
        top: 0,
        left: 0,
      }}
    >
      <div className="bg-white p-8 rounded-lg shadow-md md:max-w-md max-w-xs">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="flex items-center mb-4">
          <FaExclamationCircle className="text-red-500 mr-2" size={30} />
          {/* Using the FaExclamationCircle icon */}
          <h2 className="text-xl font-semibold text-red-500">
            Subscription Required
          </h2>
        </div>
        <p className="text-gray-700 mb-4">
          Please subscribe to continue accessing this feature.
        </p>
        <div className="flex justify-end">
          <button
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded mr-2"
            onClick={() => {
              navigate("/subscription");
            }}
          >
            Buy Now
          </button>
          {/* You can add your OK button here */}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAlert;
