import React, { useState } from "react";
import { toast } from "react-toastify";
import PageHeading from "../utils/PageHeading";
import {
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import BasicDatePicker from "../utils/MUIDatePIcker";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// Define the options for the dropdown
const neighborhoodOptions = [
  "Stedenwijk",
  "Kruidenwijk",
  "Danswijk",
  "Muziekwijk",
  "Filmwijk",
  "Waterwijk",
  "Verzetswijk",
  "Tussen de Vaarten",
  "Parkwijk",
  "Literatuurwijk",
  "Staatsliedenwijk",
  "Regenboogbuurt",
  "Bouwmeesterbuurt",
  "Molenbuurt",
  "Eilandenbuurt",
  "Bloemenbuurt",
  "Faunabuurt",
  "Landgoederenbuurt",
  "Oostvaardersbuurt",
  "Sieradenbuurt",
  "Seizoenenbuurt",
  "Stripheldenbuurt",
  "Florabuurt",
  "Indische Buurt",
  "Centrum",
  "Almere Haven",
  "De Velden",
  "De Laren",
  "Noorderplassen",
  "Nobelhorst West",
  "Nobelhorst Oost",
  "Columbuskwartier",
  "Homeruskwartier",
  "Europakwartier",
  "Oosterwold",
];

function ReferernceObjectForm({ index, onSubmit }) {
  const { t } = useTranslation();
  const [price, setPrice] = useState();
  const [propertyType, setPropertyType] = useState("");

  const [address, setAddress] = useState("");
  const [id, setId] = useState(Math.floor(Math.random() * 9) + 1);

  const [squareMeter, setSquareMeter] = useState("");
  const [plotSize, setPlotSize] = useState("");
  const [year, setYear] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [strengthsList, setStrengthsList] = useState([
    {
      strengthName: "",
      strengthAmount: 0,

      strengthData: "",
      strengthPercent: 0,
    },
  ]);

  const [weaknessesList, setWeaknessesList] = useState([
    {
      weaknessName: "",
      weaknessAmount: 0,
      weaknessData: "",
      weaknessPercent: 0,
    },
  ]);

  const [date, setDate] = useState();

  const handleInputChange = (index, field, value) => {
    const updatedStrengthsList = [...strengthsList];
    if (field === "strengthAmount") {
      // Parse value to an integer if the field is "strengthAmount"
      value = parseInt(value, 10) || 0; // Ensure a valid integer, default to 0 if parsing fails
      if (value <= 0) {
        value = 0; // Ensure the amount is greater than 0
      }
    }
    if (field === "strengthPercent") {
      // Parse value to a float if the field is "strengthPercent"
      value = parseFloat(value, 10) || 0; // Ensure a valid float, default to 0 if parsing fails
      if (value < 0) {
        value = 0; // Ensure the percentage is not negative
      } else if (value > 100) {
        value = 100; // Ensure the percentage does not exceed 100
      }
    }
    updatedStrengthsList[index][field] = value;
    setStrengthsList(updatedStrengthsList);
  };
  const handleInputChangeWeakness = (index, field, value) => {
    const updatedWeaknessesList = [...weaknessesList];
    if (field === "weaknessAmount") {
      // Parse value to an integer if the field is "strengthAmount"
      value = parseInt(value, 10) || 0; // Ensure a valid integer, default to 0 if parsing fails
      if (value <= 0) {
        value = 0; // Ensure the amount is greater than 0
      }
    }
    if (field === "weaknessPercent") {
      // Parse value to a float if the field is "strengthPercent"
      value = parseFloat(value, 10) || 0; // Ensure a valid float, default to 0 if parsing fails
      if (value < 0) {
        value = 0; // Ensure the percentage is not negative
      } else if (value > 100) {
        value = 100; // Ensure the percentage does not exceed 100
      }
    }
    updatedWeaknessesList[index][field] = value;

    setWeaknessesList(updatedWeaknessesList);
  };

  const handleAddStrength = () => {
    setStrengthsList([
      ...strengthsList,
      {
        strengthName: "",
        strengthAmount: 0,
        strengthData: "",
        strengthPercent: 0,
      },
    ]);
  };
  const handleAddWeakness = () => {
    setWeaknessesList([
      ...weaknessesList,
      {
        weaknessName: "",
        weaknessAmount: 0,
        weaknessData: "",
        weaknessPercent: 0,
      },
    ]);
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    let errors = [];
    // Validate year
    if (year === "" || !/^\d{4,}$/.test(year) || year < 1900 || year > 2100) {
      errors.push(t("errors.yearError"));
    }
    if (neighborhood === "") {
      errors.push(t("errors.neighborhoodError"));
    }

    // Check for missing or invalid fields
    if (!price) {
      errors.push(t("errors.priceRequired"));
    } else if (price < 0) {
      errors.push(t("errors.priceNegative"));
    }

    if (!propertyType) errors.push(t("errors.propertyTypeRequired"));

    if (!date) {
      errors.push(t("errors.dateRequired"));
    }

    if (!address) errors.push(t("errors.addressRequired"));

    if (!squareMeter) {
      errors.push(t("errors.squareMeterRequired"));
    } else if (squareMeter < 0) {
      errors.push(t("errors.squareMeterNegative"));
    }

    if (!plotSize) {
      errors.push(t("errors.plotSizeRequired"));
    } else if (plotSize < 0) {
      errors.push(t("errors.plotSizeNegative"));
    }

    if (!year) {
      errors.push(t("errors.yearRequired"));
    } else if (year < 0) {
      errors.push(t("errors.yearNegative"));
    } else {
      const currentYear = new Date().getFullYear();
      if (year > currentYear) {
        errors.push(t("errors.yearFuture"));
      }
    }

    if (!zipCode) {
      errors.push(t("errors.zipCodeRequired"));
    }
    // else {
    //   const zipCodeRegex = /^\d{5}$/; // Assuming a 5-digit zip code
    //   if (!zipCodeRegex.test(zipCode)) {
    //     errors.push(t("errors.zipCodeInvalid"));
    //   }
    // }

    if (!houseNumber) errors.push(t("errors.houseNumberRequired"));

    if (!city) errors.push(t("errors.cityRequired"));

    // If there are errors, display them and exit the function
    if (errors.length > 0) {
      errors.forEach((error) => {
        toast.error(error);
      });

      return;
    }

    // const formattedDate = dayjs(date).format("YYYY-MM-DD");
    const formattedDate = date;
    // Create the reference object
    let referenceObject = {
      id,
      price: parseInt(price, 10),
      propertyType,
      formattedDate,
      address,
      neighborhood: neighborhood,
      squareMeter: parseInt(squareMeter, 10),
      plotSize: parseInt(plotSize, 10),
      year: parseInt(year, 10),
      zipCode: zipCode,
      houseNumber: parseInt(houseNumber, 10),
      city,
      strengthsList,
      weaknessesList,
    };

    // Reset form fields
    setPrice("");
    setPropertyType("");
    setDate("");
    setAddress("");
    setSquareMeter("");
    setPlotSize("");
    setYear("");
    setZipCode("");
    setHouseNumber("");
    setCity("");
    setNeighborhood("");
    setStrengthsList([
      {
        strengthName: "",
        strengthAmount: 0,
        strengthData: "",
        strengthPercent: 0,
      },
    ]);
    setWeaknessesList([
      {
        weaknessName: "",
        weaknessAmount: 0,
        weaknessData: "",
        weaknessPercent: 0,
      },
    ]);

    // Submit the form data
    onSubmit(index, referenceObject);
  };

  const handleCancel = () => {
    // Reset form fields
  };

  const roomOptions = [
    { id: "kitchen", value: t("strenghts.room.options.kitchen") },
    { id: "living_room", value: t("strenghts.room.options.livingroom") },
    { id: "bedroom", value: t("strenghts.room.options.bedroom") },
    { id: "bathroom", value: t("strenghts.room.options.bathroom") },
    { id: "Other", value: t("strenghts.room.options.Other") },
  ];

  const areaOptions = [
    { id: "terrace_house", value: t("typeOfProperty.area.terrace") },
    { id: "end_house", value: t("typeOfProperty.area.end") },
    { id: "corner_house", value: t("typeOfProperty.area.corner") },
    { id: "semi_detached_house", value: t("typeOfProperty.area.semiDetached") },
    { id: "detached_house", value: t("typeOfProperty.area.detached") },
  ];

  const handleDateChange = (event) => {
    setDate(event);
  };

  return (
    <div className="md:w-5/6  w-[95%] h-auto bg-white shadow-md px-8 pt-6 pb-8 mb-4 flex flex-col my-2 mt-8 border rounded-lg">
      <form onSubmit={handleSubmit} className="md:10px xl:px-16 px-0">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => console.log("draft")}
            className=" shadow-xl md:py-2.5 md:px-4 py-1 px-1.5 text-sm font-semibold rounded-sm text-white bg-[#FF3131] hover:bg-[#FF3150] focus:outline-none"
          >
            {t("button.draft")}
          </button>
        </div>
        <h1 className="text-1B1839 font-open-sans   px-3 lg:w-[60%] w-full xl:text-2xl text-xl font-normal leading-normal tracking-wide mt-4">
          {t("propertySubTitle")}
        </h1>
        <div className="-mx-3  px-3 md:flex  mt-4">
          <div className="md:w-1/2 px-3 ">
            <label
              className="block uppercase tracking-wide text-grey-darker text-sm semi-bold mb-2"
              htmlFor="grid-first-name"
            >
              {t("zipCode.label")}
            </label>
            <input
              className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 mb-3 outline-none "
              id="grid-first-name"
              type="text"
              placeholder={t("zipCode.placeholder")}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </div>

          <div className="md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-grey-darker text-sm semi-bold mb-2"
              htmlFor="grid-last-name4"
            >
              {t("houseNumber.label")}
            </label>
            <input
              className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter roundedfull md:py-3 md:px-4 py-2 px-2 rounded-2xl outline-none"
              id="grid-last-name"
              type="number"
              // placeholder={t("houseNumber.placeholder")}
              value={houseNumber}
              onChange={(e) => {
                const newValue = e.target.value < 0 ? 0 : e.target.value;
                setHouseNumber(newValue);
              }}
            />
          </div>
        </div>
        <div className="md:w-full px-2.5 mb-6 md:mb-0 mt-2 ">
          <label
            className="block uppercase tracking-wide text-grey-darker text-sm semi-bold  mb-2"
            htmlFor="grid-address"
          >
            {t("address.label")}
          </label>
          <input
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-3 px-4 mb-3 outline-none "
            id="grid-address"
            type="text"
            placeholder={t("address.placeholder")}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        <div className="md:w-1/2 px-3">
          <label
            className="block uppercase tracking-wide text-grey-darker text-sm semi-bold mb-2"
            htmlFor="grid-last-name6"
          >
            {t("city.label")}
          </label>
          <input
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 outline-none"
            id="grid-last-name6"
            type="text"
            placeholder={t("city.placeholder")}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>

        <div className="lg:w-[60%] w-full px-3 mb-6 md:mb-0 mt-5">
          <label
            className="block tracking-wide text-grey-darker xl:text-2xl text-xl semi-bold mb-2"
            htmlFor="grid-address"
          >
            {t("sellingPrice.label")}
          </label>
          <input
            className="appearance-none block w-full text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-3 mb-6 mt-3 outline-none"
            id="grid-address"
            type="number"
            placeholder={t("sellingPrice.placeholder")}
            value={price}
            onChange={(e) => {
              const value = parseFloat(e.target.value); // Parse the input value to a floating-point number
              if (!isNaN(value) && value > 0) {
                // Check if the value is a valid number and greater than 0
                setPrice(value);
              }
            }}
          />
        </div>

        <div className="lg:w-[60%] w-full px-3 mb-6 md:mb-0">
          <label
            className="block  tracking-wide text-grey-darker xl:text-2xl text-xl  semi-bold  "
            htmlFor="grid-area"
          >
            {t("typeOfProperty.label")}
          </label>
          <select
            className=" border border-gray-300 text-gray-900 text-md w-full px-4 py-3 rounded-2xl outline-none mb-4  mt-3"
            id="grid-area"
            value={propertyType}
            onChange={(e) => setPropertyType(e.target.value)}
          >
            <option value="" disabled>
              {t("typeOfProperty.placeholder")}
            </option>
            {areaOptions.map((areaOption) => (
              <option key={areaOption.id} value={areaOption.value}>
                {areaOption.value}
              </option>
            ))}
          </select>
        </div>

        <div className="lg:w-[60%] w-full py-3">
          <label
            className="block  tracking-wide text-grey-darker xl:text-2xl text-xl  semi-bold  px-3    "
            htmlFor="grid-address"
          >
            {t("sellingDate.label")}
          </label>
          {/* <Popover placement="bottom" enterDelay={0} leaveDelay={0}>
            <PopoverHandler>
              <Input
                className=" border border-gray-300 rounded-2xl outline-none py-3 px-4 mt-2 mb-2"
                onChange={() => null}
                value={date ? format(date, "PPP") : ""}
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                mode="single"
                selected={date}
                onSelect={setDate}
                showOutsideDays
                className="border-0 "
                classNames={{
                  caption:
                    "flex justify-center py-2 mb-4 relative items-center",
                  caption_label: "text-sm font-medium text-gray-900",
                  nav: "flex items-center",
                  nav_button:
                    "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                  nav_button_previous: "absolute left-1.5",
                  nav_button_next: "absolute right-1.5",
                  table: "w-full border-collapse",
                  head_row: "flex font-medium text-gray-900",
                  head_cell: "m-0.5 w-9 font-normal text-sm",
                  row: "flex w-full mt-2",
                  cell:
                    "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                  day: "h-9 w-9 p-0 font-normal",
                  day_range_end: "day-range-end",
                  day_selected:
                    "rounded-md bg-gray-900 text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white",
                  day_today: "rounded-md bg-gray-200 text-gray-900",
                  day_outside:
                    "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                  day_disabled: "text-gray-500 opacity-50",
                  day_hidden: "invisible",
                }}
                components={{
                  IconLeft: ({ ...props }) => (
                    <ChevronLeftIcon {...props} className="h-4 w-4 stroke-2" />
                  ),
                  IconRight: ({ ...props }) => (
                    <ChevronRightIcon {...props} className="h-4 w-4 stroke-2" />
                  ),
                }}
              />
            </PopoverContent>
          </Popover> */}
          <div className="w-full px-3 py-3">
            <BasicDatePicker
              GetDateChange={handleDateChange}
              // date="2024-5-10"
            />
          </div>
        </div>

        <div className="lg:w-[60%] w-full px-3 py-3">
          <label
            className="block tracking-wide text-grey-darker xl:text-2xl text-xl semi-bold"
            htmlFor="grid-square-meter"
          >
            {t("acqLivingArea.label")}
          </label>
          <input
            className="border border-gray-300 text-gray-900 text-sm w-full p-3 rounded-2xl outline-none mt-3 mb-2"
            id="grid-square-meter"
            // placeholder={t("acqLivingArea.placeholder")}
            type="number"
            value={squareMeter}
            onChange={(e) => {
              if (parseInt(e.target.value) > 0) {
                setSquareMeter(parseInt(e.target.value));
              }
            }}
          />
        </div>

        <div className="lg:w-[60%] w-full px-3 md:mb-0 py-3">
          <label
            className="block tracking-wide text-grey-darker xl:text-2xl text-xl semi-bold"
            htmlFor="grid-square-meter3"
          >
            {t("plotSize.label")}
          </label>
          <input
            className="border border-gray-300 text-gray-900 text-sm w-full p-3 rounded-2xl outline-none mb-2 mt-2"
            id="grid-square-meter3"
            type="number"
            // placeholder={t("plotSize.placeholder")}
            value={plotSize}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (value > 0) {
                setPlotSize(value);
              }
            }}
          />
        </div>

        <div className="lg:w-[60%] xs:w-full xs:px-3 md:px-3 py-3 ">
          <label
            className="block tracking-wide text-grey-darker md:text-[22px] xs:text-[14px] semi-bold mb-2"
            htmlFor="grid-last-name"
          >
            {t("constructionYear.label")}
          </label>
          <input
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter  md:py-3 md:px-4 py-2 px-2 rounded-2xl outline-none"
            id="grid-last-name"
            type="number"
            pattern="[0-9]{4}" // Accepts only 4-digit positive numbers
            min="1900" // Minimum value allowed
            max="2099" // Maximum value allowed
            maxLength={4} // Restricts input to 4 characters
            placeholder={t("constructionYear.placeholder")}
            value={year}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d{0,4}$/.test(value)) {
                // Allow only up to 4 digits
                setYear(parseInt(value) || ""); // Convert to integer or empty string if conversion fails
              }
            }}
          />
        </div>

        <div className="lg:w-[60%] xs:w-full xs:px-3 md:px-3 py-3 ">
          <label
            className="block tracking-wide text-grey-darker md:text-[22px] xs:text-[14px] semi-bold mb-2"
            htmlFor="grid-last-name"
          >
            {t("neighbourhood.label")}
          </label>
          {/* <input
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter  md:py-3 md:px-4 py-2 px-2 rounded-2xl outline-none"
            id="grid-last-name"
            type="text"
            placeholder={t("neighbourhood.placeholder")}
            value={neighborhood}
            onChange={(e) => {
              const value = e.target.value;
              setNeighborhood(value);
            }}
          /> */}
          <Autocomplete
            id="neighborhood"
            options={neighborhoodOptions}
            value={neighborhood}
            onChange={(event, newValue) => {
              setNeighborhood(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t("neighbourhood.placeholder")}
                variant="outlined"
                sx={{
                  borderRadius: "16px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#e5e7eb",
                    },
                    "&:hover fieldset": {
                      borderColor: "#e5e7eb",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#e5e7eb",
                    },
                  },
                }}
              />
            )}
          />
        </div>

        <div className="w-full p-8">
          {strengthsList.map((strength, index) => (
            <>
              <div key={strength.id}>
                <h1 className="text-1B1839 font-open-sans  px-3 lg:w-[60%] w-full xl:text-2xl text-x font-normal leading-normal tracking-wide mt-2 mb-2">
                  {t("strenghts.label")} {index + 1}
                </h1>
              </div>
              <div key={strength.id} className="mb-4">
                <div className="-mx-3  px-3 md:flex ">
                  <div className="md:w-1/2 px-3">
                    {strength.strengthName === roomOptions[1].value ||
                    strength.strengthName === roomOptions[2].value ||
                    strength.strengthName === roomOptions[3].value ||
                    strength.strengthName === "" ||
                    strength.strengthName === roomOptions[0].value ? (
                      <select
                        id={`room-${index}`}
                        value={strength.strengthName}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "strengthName",
                            e.target.value
                          )
                        }
                        className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 mb-3 outline-none"
                      >
                        <option value="" disabled>
                          {t("strenghts.room.label")}
                        </option>
                        {roomOptions.map((roomOption) => (
                          <option key={roomOption.id} value={roomOption.value}>
                            {roomOption.value}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        id={`room-${index}`}
                        value={strength.strengthName}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "strengthName",
                            e.target.value
                          )
                        }
                        className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 mb-3 outline-none"
                        // placeholder={t("strengths.room.placeholder")}
                      />
                    )}
                  </div>

                  <div className="md:w-1/2 px-3">
                    <input
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 roundedfull py-2.5 px-4 rounded-2xl outline-none"
                      id={`amount-${index}`}
                      placeholder={t("strenghts.amount")}
                      type="number"
                      value={strength.strengthAmount}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "strengthAmount",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="-mx-3  px-3 md:flex ">
                  <div className="md:w-1/2 px-3 ">
                    <label
                      className="block  tracking-wide text-grey-darker text-sm semi-bold mb-2"
                      htmlFor={`strengths-${index}`}
                    >
                      {t("strenghts.strenghts")}
                    </label>

                    <input
                      type="text"
                      id={`strengths-${index}`}
                      value={strength.strengthData}
                      onChange={(e) =>
                        handleInputChange(index, "strengthData", e.target.value)
                      }
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 mb-3 outline-none "
                    />
                  </div>

                  <div className="md:w-1/2 px-3">
                    <label
                      className="block  tracking-wide text-grey-darker text-sm semi-bold mb-2"
                      htmlFor={`strghtPercentage-${index}`}
                    >
                      {t("strenghts.strghtPercentage")}
                    </label>
                    <input
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 roundedfull py-2.5 px-4 rounded-2xl outline-none"
                      id={`strghtPercentage-${index}`}
                      value={strength.strengthPercent}
                      type="number"
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "strengthPercent",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          ))}
          <div className="flex justify-center">
            <button
              onClick={handleAddStrength}
              type="button"
              className="justify-center mx-auto shadow-xl py-2.5 px-4 text-sm font-semibold rounded-2xl text-white bg-[#FF3131] hover:bg-[#FF3150] focus:outline-none"
            >
              + {t("button.add")}
            </button>
          </div>
        </div>
        <div className="w-full p-8">
          {weaknessesList.map((weakness, index) => (
            <>
              <div key={weakness.id}>
                <h1 className="text-1B1839 font-open-sans px-3 lg:w-[60%] w-full xl:text-2xl text-x font-normal leading-normal tracking-wide mt-2 mb-2">
                  {t("weakness.label")} {index + 1}
                </h1>
              </div>
              <div key={weakness.id} className="mb-4">
                <div className="-mx-3 px-3 md:flex">
                  <div className="md:w-1/2 px-3">
                    {weakness.weaknessName === roomOptions[1].value ||
                    weakness.weaknessName === roomOptions[2].value ||
                    weakness.weaknessName === roomOptions[3].value ||
                    weakness.weaknessName === "" ||
                    weakness.weaknessName === roomOptions[0].value ? (
                      <select
                        id={`room-${index}`}
                        value={weakness.weaknessName}
                        onChange={(e) =>
                          handleInputChangeWeakness(
                            index,
                            "weaknessName",
                            e.target.value
                          )
                        }
                        className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 mb-3 outline-none"
                      >
                        <option value="" disabled>
                          {t("weakness.room.label")}
                        </option>
                        {roomOptions.map((roomOption) => (
                          <option key={roomOption.id} value={roomOption.value}>
                            {roomOption.value}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        id={`room-${index}`}
                        value={weakness.weaknessName}
                        onChange={(e) =>
                          handleInputChangeWeakness(
                            index,
                            "weaknessName",
                            e.target.value
                          )
                        }
                        className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 mb-3 outline-none"
                        // placeholder={t("weakness.room.placeholder")}
                      />
                    )}
                  </div>

                  <div className="md:w-1/2 px-3">
                    <input
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 roundedfull py-2.5 px-4 rounded-2xl outline-none"
                      id={`amount-${index}`}
                      placeholder={t("weakness.amount")}
                      value={weakness.weaknessAmount}
                      type="number"
                      onChange={(e) =>
                        handleInputChangeWeakness(
                          index,
                          "weaknessAmount",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="-mx-3 px-3 md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      className="block  tracking-wide text-grey-darker text-sm semi-bold mb-2"
                      htmlFor={`weaknesses-${index}`}
                    >
                      {t("weakness.weaknesses")}
                    </label>

                    <input
                      type="text"
                      id={`weaknesses-${index}`}
                      value={weakness.weaknessData}
                      onChange={(e) =>
                        handleInputChangeWeakness(
                          index,
                          "weaknessData",
                          e.target.value
                        )
                      }
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-2xl py-2.5 px-4 mb-3 outline-none"
                    />
                  </div>

                  <div className="md:w-1/2 px-3">
                    <label
                      className="block  tracking-wide text-grey-darker text-sm semi-bold mb-2"
                      htmlFor={`wknPercentage-${index}`}
                    >
                      {t("weakness.wknPercentage")}
                    </label>
                    <input
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 roundedfull py-2.5 px-4 rounded-2xl outline-none"
                      id={`wknPercentage-${index}`}
                      value={weakness.weaknessPercent}
                      type="number"
                      onChange={(e) =>
                        handleInputChangeWeakness(
                          index,
                          "weaknessPercent",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          ))}
          <div className="flex justify-center">
            <button
              onClick={handleAddWeakness}
              type="button"
              className="justify-center mx-auto shadow-xl py-2.5 px-4 text-sm font-semibold rounded-2xl text-white bg-[#FF3131] hover:bg-[#FF3150] focus:outline-none"
            >
              + {t("button.add")}
            </button>
          </div>
        </div>

        <div className="mt-4 w-2/3 md:1/2 flex justify-end ml-auto">
          <button
            onClick={() => handleCancel()}
            type="button" // Add type="button" to specify it as a button
            className="w-[45%] shadow-xl py-2.5 px-4 text-sm font-semibold rounded-2xl text-black border border-[#000] focus:outline-none mr-2"
          >
            {t("button.cancel")}
          </button>
          <button
            type="submit"
            className="w-[45%] shadow-xl py-2.5 px-4 text-sm font-semibold rounded-2xl text-white bg-[#FF3131] hover:bg-[#FF3150] focus:outline-none"
          >
            {t("button.submit")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ReferernceObjectForm;
