import React from "react";
import Header from "../components/Header.jsx";
import Sidebar from "../components/SideBar.jsx";
import ReferenceObject from "../components/ReferenceObject.jsx";

function ReferenceObjectPage() {
  return <ReferenceObject />;
}

export default ReferenceObjectPage;
