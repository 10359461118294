import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router-dom for routing
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

function PaymentSuccess() {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <CheckCircleOutlineOutlinedIcon
        style={{ fontSize: 100, color: "#34D399" }}
      />
      <h2 className="text-3xl font-bold mt-6">Payment Successful!</h2>
      <p className="text-gray-600 mt-2">Thank you for your subscription.</p>
      <Link
        to="/"
        className="mt-4 bg-[#FF3131] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Back to Home
      </Link>
    </div>
  );
}

export default PaymentSuccess;
