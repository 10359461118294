const StandardParameter = ({stroke = "#202020"}) => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16731 9.01198H17.2648M4.23494 12.5343H14.8415M4.16731 16.0567H17.2648M4.23494 19.579H14.8415M18.809 1.93345H18.206C18.187 1.67966 18.0728 1.44242 17.8862 1.26931C17.6996 1.0962 17.4545 1 17.2 1C16.9455 1 16.7004 1.0962 16.5139 1.26931C16.3273 1.44242 16.2131 1.67966 16.194 1.93345H5.24938C5.23037 1.67966 5.11613 1.44242 4.92957 1.26931C4.74301 1.0962 4.4979 1 4.2434 1C3.98889 1 3.74378 1.0962 3.55722 1.26931C3.37066 1.44242 3.25642 1.67966 3.23741 1.93345H2.63438C2.20871 1.93037 1.79887 2.09464 1.49316 2.39086C1.18745 2.68708 1.01035 3.09155 1.00001 3.51711V5.59107H20.4321V3.55092C20.4321 3.12292 20.2625 2.71236 19.9604 2.40919C19.6583 2.10601 19.2483 1.93495 18.8203 1.93345H18.809ZM20.4321 5.60798H1.00001V21.3881C0.999265 21.6 1.04045 21.8099 1.12119 22.0058C1.20192 22.2017 1.32062 22.3797 1.47044 22.5295C1.62026 22.6793 1.79825 22.798 1.99414 22.8788C2.19003 22.9595 2.39996 23.0007 2.61184 23H18.809C19.0212 23.0014 19.2315 22.961 19.428 22.8811C19.6245 22.8011 19.8033 22.6831 19.9541 22.5339C20.1049 22.3846 20.2247 22.2071 20.3068 22.0115C20.3888 21.8158 20.4314 21.6059 20.4321 21.3938V5.60798Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Comparison = ({stroke = "#202020"}) => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1.5"
        width="7"
        height="19"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="13"
        y="1.5"
        width="7"
        height="19"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Profile = ({stroke = "#202020"}) => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.98841 18.1894L6.98836 18.1894C6.57051 18.2504 6.2501 18.6171 6.2501 19.0646V19.0655C6.24955 19.2588 6.31274 19.4469 6.42988 19.6007C6.69198 19.942 6.96104 20.2778 7.23689 20.6081L7.64811 21.1004H7.00665H5.5893H5.44357L5.35348 20.9858C5.2636 20.8716 5.17334 20.7553 5.08222 20.6371C4.7332 20.1844 4.5501 19.6274 4.5501 19.0652C4.5501 17.7997 5.46418 16.6945 6.74272 16.5078C10.6545 15.9361 13.3804 15.9882 17.2635 16.5256M6.98841 18.1894L18.6463 20.4671C18.9725 20.067 19.1505 19.5665 19.1501 19.0503H19.4501C19.4501 19.0502 19.4501 19.0501 19.4501 19.0501C19.45 17.7901 18.5301 16.7006 17.2635 16.5256M6.98841 18.1894C10.7213 17.6441 13.2847 17.6904 17.0295 18.2088L6.98841 18.1894ZM17.2635 16.5256L17.2223 16.8228L17.2635 16.5256ZM13.1481 12.0215C12.7842 12.1723 12.3941 12.2499 12.0001 12.2499C11.2044 12.2499 10.4414 11.9338 9.87878 11.3712C9.31617 10.8086 9.0001 10.0455 9.0001 9.24985C9.0001 8.4542 9.31617 7.69114 9.87878 7.12853C10.4414 6.56592 11.2044 6.24985 12.0001 6.24985C12.3941 6.24985 12.7842 6.32745 13.1481 6.47821C13.5121 6.62898 13.8428 6.84996 14.1214 7.12853L14.3335 6.9164L14.1214 7.12853C14.4 7.40711 14.621 7.73783 14.7717 8.1018C14.9225 8.46578 15.0001 8.85589 15.0001 9.24985C15.0001 9.64382 14.9225 10.0339 14.7717 10.3979C14.621 10.7619 14.4 11.0926 14.1214 11.3712C13.8428 11.6497 13.5121 11.8707 13.1481 12.0215ZM12.0001 13.9499C13.2466 13.9499 14.4421 13.4547 15.3235 12.5733C16.2049 11.6918 16.7001 10.4964 16.7001 9.24985C16.7001 8.00334 16.2049 6.80787 15.3235 5.92645C14.4421 5.04503 13.2466 4.54985 12.0001 4.54985C10.7536 4.54985 9.55812 5.04503 8.6767 5.92645C7.79527 6.80787 7.3001 8.00334 7.3001 9.24985C7.3001 10.4964 7.79527 11.6918 8.6767 12.5733C9.55812 13.4547 10.7536 13.9499 12.0001 13.9499Z" fill={stroke} stroke={stroke} strokeWidth="0.6"/>
<path d="M21.6 12C21.6 17.3019 17.3019 21.6 12 21.6C6.69814 21.6 2.4 17.3019 2.4 12C2.4 6.69814 6.69814 2.4 12 2.4C17.3019 2.4 21.6 6.69814 21.6 12ZM12 23.3C18.241 23.3 23.3 18.241 23.3 12C23.3 5.75901 18.241 0.7 12 0.7C5.75901 0.7 0.7 5.75901 0.7 12C0.7 18.241 5.75901 23.3 12 23.3Z" fill={stroke} stroke={stroke} strokeWidth="0.6"/>
</svg>

  );
};

const SubscriptionIcon = ({stroke = "#202020"}) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0344 14.6208C22.042 14.6361 22.0458 14.7469 22.0458 14.9531C22.0458 15.1594 22.0497 15.4229 22.0573 15.7438C22.0649 16.0646 22.0649 16.4351 22.0573 16.8552C22.0497 17.2753 22.0497 17.6993 22.0573 18.1271C22.0649 18.5549 22.0611 18.9903 22.0458 19.4333C22.0306 19.8764 22.0306 20.266 22.0458 20.6021C22.0611 20.9382 22.0573 21.2399 22.0344 21.5073C22.0115 21.7747 22.0115 21.9389 22.0344 22H0.034375V20.6135C0.034375 20.2545 0.0305556 19.8611 0.0229167 19.4333C0.0152778 19.0056 0.0152778 18.574 0.0229167 18.1385C0.0305556 17.7031 0.0267361 17.2753 0.0114583 16.8552C-0.00381944 16.4351 -0.00381944 16.0684 0.0114583 15.7552C0.0267361 15.442 0.0267361 15.1747 0.0114583 14.9531C-0.00381944 14.7316 0.00381944 14.6208 0.034375 14.6208L4.42292 1.46667H8.83438V2.93333H5.47708L1.56979 14.6667H6.35938L7.82604 17.6H14.2427L15.7094 14.6667H20.499L16.5917 2.93333H13.2344V1.46667H17.6458L22.0344 14.6208ZM20.5677 16.1333H16.626L15.1594 19.0667H6.90938L5.44271 16.1333H1.50104V20.5333H20.5677V16.1333ZM10.301 10.6906V0H11.7677V10.6906L15.1365 7.33333L16.1677 8.36458L11.0344 13.4979L5.90104 8.36458L6.93229 7.33333L10.301 10.6906Z"
        fill={stroke}
        stroke={stroke}
      />
    </svg>
  );
};

const InputImage = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11.514" cy="11.0975" r="10.764" fill="white" />
      <circle
        cx="11.514"
        cy="11.0975"
        r="10.264"
        stroke="black"
        stroke-opacity="0.15"
      />
      <path
        d="M14.4086 8.13436L15.6409 9.36611L14.4086 8.13436ZM15.2011 7.04923L11.8689 10.3814C11.6967 10.5533 11.5793 10.7724 11.5314 11.011L11.2236 12.5517L12.7643 12.2433C13.0029 12.1956 13.2217 12.0786 13.3939 11.9064L16.7261 8.57423C16.8262 8.4741 16.9056 8.35522 16.9598 8.22439C17.014 8.09356 17.0419 7.95334 17.0419 7.81173C17.0419 7.67012 17.014 7.5299 16.9598 7.39907C16.9056 7.26824 16.8262 7.14937 16.7261 7.04923C16.6259 6.9491 16.5071 6.86967 16.3762 6.81548C16.2454 6.76129 16.1052 6.7334 15.9636 6.7334C15.822 6.7334 15.6817 6.76129 15.5509 6.81548C15.4201 6.86967 15.3012 6.9491 15.2011 7.04923V7.04923Z"
        stroke="#202020"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8779 13.7153V15.4608C15.8779 15.7695 15.7553 16.0655 15.5371 16.2837C15.3189 16.5019 15.0229 16.6245 14.7143 16.6245H8.31406C8.00544 16.6245 7.70945 16.5019 7.49122 16.2837C7.27299 16.0655 7.15039 15.7695 7.15039 15.4608V9.06065C7.15039 8.75202 7.27299 8.45604 7.49122 8.2378C7.70945 8.01957 8.00544 7.89697 8.31406 7.89697H10.0596"
        stroke="#202020"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export {Profile, SubscriptionIcon, Comparison, StandardParameter, InputImage};
