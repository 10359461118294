export const propertyData = [
  {
    house: "Terraced House",
    ContYear: 2023,
    Area: 23213,
    sqft: 123,
    address: "1115 Martha Street, Kingman",
    floors: 1,
    price: 10000,
    sellingDate: "6/21/19",
    AcquireArea: 1400,
    PlotArea: 1800,
    Strenghts: 20,
    Weaknesses: 10,
  },
  {
    house: "Terraced House",
    ContYear: 2023,
    Area: 23213,
    sqft: 123,
    address: "1115 Martha Street, Kingman",
    floors: 1,
    price: 10000,
    sellingDate: "6/21/19",
    AcquireArea: 1400,
    PlotArea: 1800,
    Strenghts: 20,
    Weaknesses: 10,
  },
  {
    house: "Terraced House",
    ContYear: 2023,
    Area: 23213,
    sqft: 123,
    address: "1115 Martha Street, Kingman",
    floors: 1,
    price: 10000,
    sellingDate: "6/21/19",
    AcquireArea: 1400,
    PlotArea: 1800,
    Strenghts: 20,
    Weaknesses: 10,
  },
  {
    house: "Terraced House",
    ContYear: 2023,
    Area: 23213,
    sqft: 123,
    address: "1115 Martha Street, Kingman",
    floors: 1,
    price: 10000,
    sellingDate: "6/21/19",
    AcquireArea: 1400,
    PlotArea: 1800,
    Strenghts: 20,
    Weaknesses: 10,
  },
  {
    house: "Terraced House",
    ContYear: 2023,
    Area: 23213,
    sqft: 123,
    address: "1115 Martha Street, Kingman",
    floors: 1,
    price: 10000,
    sellingDate: "6/21/19",
    AcquireArea: 1400,
    PlotArea: 1800,
    Strenghts: 20,
    Weaknesses: 10,
  },
  {
    house: "Terraced House",
    ContYear: 2023,
    Area: 23213,
    sqft: 123,
    address: "1115 Martha Street, Kingman",
    floors: 1,
    price: 10000,
    sellingDate: "6/21/19",
    AcquireArea: 1400,
    PlotArea: 1800,
    Strenghts: 20,
    Weaknesses: 10,
  },
  {
    house: "Terraced House",
    ContYear: 2023,
    Area: 23213,
    sqft: 123,
    address: "1115 Martha Street, Kingman",
    floors: 1,
    price: 10000,
    sellingDate: "6/21/19",
    AcquireArea: 1400,
    PlotArea: 1800,
    Strenghts: 20,
    Weaknesses: 10,
  },
  {
    house: "Terraced House",
    ContYear: 2023,
    Area: 23213,
    sqft: 123,
    address: "1115 Martha Street, Kingman",
    floors: 1,
    price: 10000,
    sellingDate: "6/21/19",
    AcquireArea: 1400,
    PlotArea: 1800,
    Strenghts: 20,
    Weaknesses: 10,
  }

  // Add more property data objects as needed
];
