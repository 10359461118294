import React from "react";
import Sidebar from "../components/SideBar";
import Header from "../components/Header";
import ViewProfile from "../components/ViewProfile";

function ProfileSetting() {
  return <ViewProfile />;
}

export default ProfileSetting;
