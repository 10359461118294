import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IOSSwitch } from "../../utils/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AuthContext } from "../../context/AuthContext";
import { GetUserProfile } from "../../apis/userProfile/getUserProfile";
import { CreateSubscription } from "../../apis/payment/create-subscription";
import Loader from "../Loader";
import { CancelSubscription } from "../../apis/payment/cancel-subscription";
import { toast } from "react-toastify";

function SubscriptionCard() {
  // Define state variables
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { logOut, isLoggedIn } = useContext(AuthContext);
  const auth = isLoggedIn();

  const [choosenPlan, setChoosenPlan] = useState(null);
  const [planActive, setPlanActive] = useState(null);
  const [loader, setLoader] = useState(true);

  const [isChecked, setIsChecked] = useState(true); // Initially checked
  const [buttonLoading, setButtonLoading] = useState(false);
  // Pricing data
  const [plans, setPlans] = useState([
    {
      name: t("subscription.plans.basic.heading"),
      price: 0.0,
      priceId: "price_1P13weDuMOkDaIdDPrV17Fca",
      period: `2 ${t("subscription.month")}`,
      plan: t("subscription.plans.basic.heading"),
      planType: t("subscription.plans.basic.planType"),
      featuresType: t("subscription.features.heading.starter"),
      loader: false,
      features: [
        t("subscription.features.one"),
        t("subscription.features.two"),
        t("subscription.features.three"),
      ],
    },
    {
      name: t("subscription.plans.monthly.heading"),
      price: 20.99,
      period: `6 ${t("subscription.month")}`,
      priceId: "price_1P13tMDuMOkDaIdDxS0QCB9W",
      plan: t("subscription.plans.monthly.heading"),
      planType: t("subscription.plans.monthly.planType"),
      loader: false,
      featuresType: t("subscription.features.heading.monthly"),
      features: [
        t("subscription.features.four"),
        t("subscription.features.five"),
        t("subscription.features.three"),
        t("subscription.features.four"),
        t("subscription.features.five"),
        t("subscription.features.three"),
      ],
    },
    {
      name: t("subscription.plans.yearly.heading"),
      price: 88.99,
      period: `12 ${t("subscription.month")}`,
      plan: t("subscription.plans.yearly.heading"),
      planType: t("subscription.plans.yearly.planType"),
      priceId: "price_1P13xxDuMOkDaIdDWKR9Vrdi",
      loader: false,
      featuresType: t("subscription.features.heading.yearly"),
      features: [
        t("subscription.features.six"),
        t("subscription.features.seven"),
        t("subscription.features.five"),
        t("subscription.features.four"),
        t("subscription.features.six"),
        t("subscription.features.seven"),
        t("subscription.features.five"),
        t("subscription.features.four"),
        t("subscription.features.six"),
      ],
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (auth.isLoggedIn) {
          const response = await GetUserProfile(auth.userData.token, logOut);
          if (response.data && response.data.status === true) {
            // Successful login

            const matchingPlan = plans.find(
              (plan) =>
                plan.priceId === response.data.userDetails.subscriptionPriceId
            );
            console.log(matchingPlan);

            if (matchingPlan) {
              setChoosenPlan(matchingPlan);
            }
            setPlanActive(response.data.userDetails.subscriptionStatus);
            setEmail(response.data.userDetails.email);
          }
        } else {
          logOut();
          console.error("User data or token is missing.", auth?.isLoggedIn);
        }
      } catch (error) {
        // Handle errors
        console.log(error);
      } finally {
        setLoader(false);
      }
    };

    fetchData();
  }, [planActive]);

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
  };

  // Function to handle plan selection
  const handleCancelation = async () => {
    try {
      const response = await CancelSubscription(auth.userData.token, logOut);
      if (response.data && response.data.success === true) {
        // Successful login
        toast.success(t("subscription.cancelation.success"));

        window.location.reload();
      } else {
        toast.error(t("subscription.cancelation.failure"));

        console.log("Subscription cancellation failed:", response.data);
        // toast.error(response.data.message);
      }
    } catch (error) {
      // Handle errors
      console.log(error);
    }
  };

  const handlePlanSelect = async (plan) => {
    setPlans(plans.map((p) => (p === plan ? { ...p, loader: true } : p)));
    setSelectedPlan(plan);
    setButtonLoading(true);

    if (email) {
      try {
        if (auth.isLoggedIn) {
          const response = await CreateSubscription(
            auth.userData.token,
            logOut,
            email,
            plan.priceId
          );
          if (response.data && response.data.status === true) {
            // Successful login
            // console.log("Subscription created successfully:", response.data);
            window.location.href = response.data.stripeUrl;
          }
        } else {
          logOut();
          console.error("User data or token is missing.", auth?.isLoggedIn);
        }
      } catch (error) {
        // Handle errors
        console.log(error);
      } finally {
        setButtonLoading(false);
      }
    }
  };

  return (
    <>
      {loader ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Loader />
        </div>
      ) : planActive === "active" ? (
        <section>
          <div className="w-full px-6 py-8 mx-auto lg:py-24">
            <div className="w-full mb-8 lg:mb-12 flex justify-between">
              <div>
                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
                  {t("subscription.heading")}
                </h2>
                <p className="mb-5 font-light text-gray-500 sm:text-base">
                  {t("subscription.description")}
                </p>
              </div>
              <div>
                <span className="text-sm font-bold text-gray-900">
                  {t("subscription.renewButton")}
                </span>
                <div className="flex items-center">
                  <label className="inline-flex items-center relative cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <div className="flex justify-center">
                      <div className="ml-10">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                sx={{ m: 1 }}
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                              />
                            }
                            onChange={handleCheckboxChange}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
              <div className="flex flex-col max-w-lg p-8 mx-auto text-gray-900  border border-gray-100 rounded-lg shadow xl:p-8 border-black-700 bg-[#FFE8E1]">
                {/* Card content */}
                <div className="flex flex-row justify-between">
                  <h3 className="text-2xl font-semibold">
                    {choosenPlan?.name}
                  </h3>
                  <p className="mb-2 font-light text-gray-500 sm:text-[16px]">
                    {choosenPlan?.period}
                  </p>
                </div>
                <p className="mb-2 font-light text-gray-500 sm:text-[16px]">
                  {choosenPlan?.planType}
                </p>

                <div className="flex items-baseline justify-center my-6">
                  <span className="mr-2 text-3xl font-extrabold">
                    €{choosenPlan?.price}
                  </span>
                  <span className="text-gray-500">
                    /{t("subscription.month")}
                  </span>
                </div>
                <p className="font-light text-gray-500 sm:text-base p-4">
                  {t("subscription.shortdescription")}
                </p>
                <p className="font-light justify-center text-gray-500 sm:text-base mt-6 mb-4">
                  {choosenPlan?.featuresType}
                </p>
                <ul role="list" className="mb-8 space-y-4 text-left">
                  {choosenPlan?.features.map((feature, index) => (
                    <li key={index} className="flex items-center space-x-3">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                {/* Button for each plan */}
                <button
                  onClick={() => handleCancelation()}
                  type="button" // Add type="button" to specify it as a button
                  className="bg-[#FF3131] hover:bg-[#FF3150]  py-2.5 rounded-2xl font-medium text-sm px-5text-center mt-auto  text-black border"
                >
                  {t("subscription.buttons.unsubscribe")}
                </button>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section>
          <div className="w-full px-6 py-8 mx-auto lg:py-24">
            <div className="w-full mb-8 lg:mb-12 flex justify-between">
              <div>
                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
                  {t("subscription.heading")}
                </h2>
                <p className="mb-5 font-light text-gray-500 sm:text-base">
                  {t("subscription.description")}
                </p>
              </div>
              <div>
                <span className="text-sm font-bold text-gray-900">
                  {t("subscription.renewButton")}
                </span>
                <div className="flex items-center">
                  <label className="inline-flex items-center relative cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <div className="flex justify-center">
                      <div className="ml-10">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                sx={{ m: 1 }}
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                              />
                            }
                            onChange={handleCheckboxChange}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
              {plans.map((plan) => (
                <div
                  key={plan.name}
                  className={`flex flex-col max-w-lg p-8 mx-auto text-gray-900  border border-gray-100 rounded-lg shadow xl:p-8 ${
                    selectedPlan === plan.name
                      ? "border-black-700 bg-[#FFE8E1]"
                      : "bg-white"
                  }`}
                >
                  {/* Card content */}
                  <div className="flex flex-row justify-between">
                    <h3 className="text-2xl font-semibold">{plan.name}</h3>
                    <p className="mb-2 font-light text-gray-500 sm:text-[16px]">
                      {plan.period}
                    </p>
                  </div>
                  <p className="mb-2 font-light text-gray-500 sm:text-[16px]">
                    {plan.planType}
                  </p>

                  <div className="flex items-baseline justify-center my-6">
                    <span className="mr-2 text-3xl font-extrabold">
                      €{plan.price}
                    </span>
                    <span className="text-gray-500">
                      /{t("subscription.month")}
                    </span>
                  </div>
                  <p className="font-light text-gray-500 sm:text-base p-4">
                    {t("subscription.shortdescription")}
                  </p>
                  <p className="font-light justify-center text-gray-500 sm:text-base mt-6 mb-4">
                    {plan.featuresType}
                  </p>
                  <ul role="list" className="mb-8 space-y-4 text-left">
                    {plan.features.map((feature, index) => (
                      <li key={index} className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                  {/* Button for each plan */}
                  <button
                    onClick={() => handlePlanSelect(plan)}
                    type="button" // Add type="button" to specify it as a button
                    className={`${
                      selectedPlan === plan.name
                        ? "bg-[#FF3131] hover:bg-[#FF3150]"
                        : "shadow-xl"
                    } py-2.5 rounded-2xl font-medium text-sm px-5text-center mt-auto  text-black border border-[#000]`}
                  >
                    {plan.loader ? (
                      <Loader />
                    ) : plan.name === t("subscription.plans.basic.heading") ? (
                      t("subscription.buttons.start")
                    ) : (
                      t("subscription.buttons.buy")
                    )}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default SubscriptionCard;
