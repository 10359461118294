import axios from "axios";

export const CancelSubscription = async (token, logOut) => {
  let res = "";
  let err = "";

  try {
    // Make the API request using axios.put
    res = await axios.put(
      "https://staging.pythagorasvastgoedcalculator.nl/api/cancel-subscription",
      {}, // Empty object as the second argument for data
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log("Token Expired. Logging Out");
      logOut();
    } else {
      console.log("Error:", error.message);
    }

    err = error;
  }
  return res || err;
};
