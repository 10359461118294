import React from "react";

import ComparisonComponent from "../components/ComparisonComponent";

const Comparision = () => {
  return (
    <>
      <ComparisonComponent />
    </>
  );
};

export default Comparision;
