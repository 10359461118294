import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/SideBar";
import EditProfile from "../components/EditProfile";

function EditProfilePage() {
  return <EditProfile />;
}

export default EditProfilePage;
