import axios from "axios";

export const CreateSubscription = async (token, logOut, email, priceId) => {
  let res = "";
  let err = "";

  const data = {
    email: email,
    priceId: priceId,
  };

  try {
    // Make the API request using axios.post
    res = await axios.post(
      "https://staging.pythagorasvastgoedcalculator.nl/api/create-subscription",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log("Token Expired. Logging Out");
      logOut();
    } else {
      console.log("Error:", error.message);
    }

    err = error;
  }
  return res || err;
};
