import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/SideBar";
import Parameters from "../components/Parameters";

function StandardParameters() {
  return <Parameters />;
}

export default StandardParameters;
